@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5 !important;
}

a{
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, button, .btn{
  margin: 0;
  padding: 0;
  font-family: "Proza Libre", sans-serif;
}

h6, span, p{
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

.br16{
  border-radius: 16px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bgDanger{
  color: white !important;
  background-color: #FF5733 !important;
}

.card-shadow{
  box-shadow: 0px 5px 10px 0px #0000001A;
}