* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

:root {
  --body-font: "Open Sans", sans-serif;
  --heading-font: "Proza Libre", sans-serif;
  --primary-blue: #575a89;
  --secondary-blue: #6a778b;
  --dark-green: #698a17;
  --green: #a4cd39;
  --white: #fff;
  --body-bg: #f5f5f5;
  --red: #ff5733;
  --black: #323232;
  --grey-1: #adb4bd;
  --grey-0: #edeeef;
  --highlight-blue: #1c75bc;
  --yellow: #ffbd00;
  --bs-danger: var(--red);
  --bs-danger-rgb: 255, 87, 51;
}

.btn-primary {
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--primary-blue);
  --bs-btn-border-color: var(--primary-blue);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--primary-blue);
  --bs-btn-hover-border-color: var(--primary-blue);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--primary-blue);
  --bs-btn-active-border-color: var(--primary-blue);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white);
  --bs-btn-disabled-bg: var(--primary-blue);
  --bs-btn-disabled-border-color: var(--primary-blue);
  --mdb-btn-bg: var(--primary-blue);
  --mdb-btn-color: var(--white);
  --mdb-btn-box-shadow: 0 4px 9px -4px var(--primary-blue);
  --mdb-btn-hover-bg: #386bc0;
  --mdb-btn-hover-color: var(--white);
  --mdb-btn-focus-bg: #386bc0;
  --mdb-btn-focus-color: var(--white);
  --mdb-btn-active-bg: #3566b6;
  --mdb-btn-active-color: var(--white);
  --mdb-btn-box-shadow-state: 0 8px 9px -4px rgba(59, 113, 202, 0.3), 0 4px 18px 0 rgba(59, 113, 202, 0.2);
}

hr {
  margin: 1rem 0 !important;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #a4cd39;
}

.btn-outline-primary {
  --bs-btn-color: var(--primary-blue);
  --bs-btn-border-color: var(--primary-blue);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary-blue);
  --bs-btn-hover-border-color: var(--primary-blue);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary-blue);
  --bs-btn-active-border-color: var(--primary-blue);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--primary-blue);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--primary-blue);
  --bs-gradient: none;
}

html,
body {
  overflow-x: hidden !important;
  scroll-behavior: auto !important;
}

body {
  line-height: 1.6;
  font-family: var(--body-font);
  background-color: var(--body-bg);
  color: var(--secondary-blue);
  overflow: unset;
}

.heading {
  line-height: 1.6;
  font-weight: 700;
  color: var(--secondary-blue);
  font-family: var(--heading-font);
}

.title {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 0;
  font-weight: 500;
  color: #adb4bd;
  font-family: var(--heading-font);
}

.subtitle {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0.5rem;
  font-weight: 400;
  text-align: left;
  color: #adb4bd;
  font-family: var(--body-font);
}

/* LOGIN POPUP MODAL CSS START  */

.popupModal .modal-content,
.popupModal .modal-body {
  padding: 0;
  overflow: hidden;
}

.otp_inp_field {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #575a8980;

  font-family: var(--body-font);
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  color: #c4c4c4;
}

.otp-field {
  margin: 1.3rem 0;
}

.reotp {
  margin: 1.1rem 0;
}

.reotp small {
  color: var(--primary-blue);
  line-height: 25px;
  font-family: var(--body-font);
}

.reotp small Link {
  font-weight: 600;
  color: #1c75bc;
}

/* FILTER BUTTON CSS  */

.search-bar {
  margin-top: -2rem;
  position: relative;
  z-index: 9;
}
button.filter-btn {
  box-shadow: 0px 0px 10px 0px #00000033;
  border-radius: 16px;
  padding: 16px 8px;
  border: none;
  background-color: var(--white);
}
.search_sec h5 {
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
  font-weight: 500;
  text-align: left;
  color: var(--secondary-blue);
}
.search_sec p {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  margin: 0;
  color: var(--primary-blue);
}

.filter_box {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* SEARCH MODEL CSS START */

.search-modal .modal-content {
  height: 100vh;
  padding: 20px;
  border-radius: 0;
  background-color: var(--white);
}

.search-modal.modal-body {
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--white);
}

.modalMap .mb-3 {
  width: 10%;
  min-width: 100px;
  color: var(--primary-blue);
  text-decoration: none;
  margin-right: 16px;
}

.cstm_select img {
  width: 100%;
  margin-bottom: 0.5rem;
}

.modalMap .mb-3:hover .cstm_select img {
  cursor: pointer;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  transition: all 0.25s linear;
  margin-top: -1px;
}

.dotted {
  border: 2px dashed #575a89;
  width: 100%;
}

.cstm_select label {
  font-family: var(--body-font);
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  color: var(--primary-blue);
}

span.border-dash {
  width: 100%;
  height: 1px;
  display: inline-block;
  margin-bottom: 3px;
  border: 1px dashed #adb4bd80;
}

.search-modal span.badge {
  font-size: 1rem;
  border: 1px solid #c4c4c4;
  margin: 0 8px 8px 0;
  border-radius: 8px;
  font-family: var(--heading-font);
  font-weight: 600;
  color: var(--primary-blue);
  margin: 0 8px 8px 0;
  padding: 0.5rem 1rem;
}

.search-modal span.badge span {
  font-size: 24px;
  font-weight: 300;
  display: inline-block;
  margin-right: 0.4rem;
}

.search-modal span.badge.active {
  border: 1px solid var(--bs-danger);
  background-color: var(--bs-danger);
  color: white;
}

.search-modal span.badge.active span {
  transform: rotate(45deg);
}

.submit-btn {
  background-color: #ff573366;
  border-radius: 8px;
  padding: 16px;
  font-family: var(--heading-font);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: var(--white);
  text-transform: uppercase;
}

.submit-btn.active {
  background-color: var(--red);
  color: var(--white);
  border: none;
}

.sepration_txt {
  font-family: var(--body-font);
  font-size: 10px;
  line-height: 13px;
  font-weight: 400;
  text-align: left;
  color: var(--primary-blue);
}

.resend_otp {
  margin-top: 1rem;
  font-family: var(--body-font);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: var(--primary-blue);
}

#otpDuration {
  color: var(--red);
}

/* SWIPER CUSTOM BUTTON CSS  */

.swiper-button-prev:after {
  content: url("../../assets/images/prev-btn.png");
  line-height: 0;
}

.swiper-button-next:after {
  content: url("../../assets/images/next-btn.png");
  line-height: 0;
}

.swiper-button-prev,
.swiper-button-next {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  justify-content: center;
  background-color: var(--white);
  top: var(--swiper-navigation-top-offset, 58%);
}

/* NAVBAR CSS START */

.mobile-menu {
  top: 0;
  right: -100%;
  width: calc(100% - 2rem);
  position: fixed;
  bottom: 0;
  z-index: 1045;
  background: #fff;
  transition: right .3s ease-in-out;
}

.mobile-menu.show{
  right: 0;
}

.offcanvas-body .nav-item {
  margin-bottom: 8px;
}

.offcanvas-body .nav-item a{
  padding: .7rem 0;
  display: block;
  text-align: left;
}

a.navbar-brand img {
  height: 44px;
}

.nav-item a {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #0000008c;
  text-transform: lowercase;
  font-family: var(--heading-font);
}

.nav-item .active {
  color: #000;
  padding: 4px 0;
}

.newTrip {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #575a89;
  padding: 8px;
  text-transform: uppercase;
}

.signIn {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
  text-transform: uppercase;
  background-color: var(--white);
}

.signIn:hover {
  color: var(--white);
  background: var(--primary-blue);
}

.btn.bgDanger {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ffffff40;
  text-transform: uppercase;
}

.loginImage {
  object-fit: fill;
}

.socialIc {
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 6px 0px #00000040;

  padding: 15px 20px;
  background: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
}

.authentication_content {
  width: 100%;
}

.authentication_content h3 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  color: var(--primary-blue);
}

.login_social_media_icon {
  margin: 1.8rem 0 2rem;
}

.login_social_media_icon small {
  color: #adb4bd;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  background-color: transparent;
}

.login_main_screen {
  padding: 3rem;
}

.floatingIc {
  padding-left: 1rem;
  line-height: 42px;
  border-radius: 0 7px 7px 0;
  border-color: #ced4da;
  background-color: transparent;
}

.input-group-text {
  background: #e9ecef;
}

.input-group-text svg {
  font-size: 0.9rem;
}

.inputIc {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  opacity: 0.3;
}

.step3 label.formLabel {
  position: absolute;
  top: 1.45rem;
  left: 2.3rem;
  background: transparent;
  padding: 0 1rem;

  font-family: var(--body-font);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--primary-blue);
  text-transform: capitalize;
}

.step3 .form-control:focus .step3 label.formLabel {
  top: 0.45rem;
}

.authentication_form button {
  margin-bottom: 1rem;
}

.btn-custom {
  width: 100%;
  line-height: 44px;
  background: #93bd33;
}

.btn-custom:hover {
  background: #94bd33d8;
}

.footer-Link {
  margin: 1rem 0 2rem;
}

.footer-Link a {
  font-size: 18px;
  line-height: 30px;
  margin-right: 0.8rem;
  color: #adb4bd;
  text-decoration: none;
  display: inline-block;
}

.footer-Link a:hover {
  color: #93bd33;
}
.pointer {
  cursor: pointer;
}

/* -------------------------------------- HERO SECTION CSS  */

.hero_section {
  background-color: #575a89;
}

.banner-title,
.banner-title span {
  line-height: 1;
  font-family: var(--heading-font);
}

#slt {
  text-decoration: none;
}

#slt i {
  font-size: 14px;
}

#slt i::before {
  content: "\a";
  white-space: pre;
  line-height: 0%;
}

.my-trips .card {
  border-radius: 16px;
  overflow: hidden;
}

.hero_section .swiper-slide,
.trip-card {
  max-width: 520px;
}

.trip-card-body {
  background-color: var(--white);
  padding: 1.5rem;
  border-radius: 16px;
  margin-bottom: 24px;
  z-index: 1;
  overflow: hidden;
}

.trip-card-prop-img {
  clip-path: polygon(70% 0, 100% 0, 100% 100%, 50% 100%);
  filter: contrast(0.7) brightness(0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-size: contain;
}

.trip-card-head,
.trip-card-foot {
  z-index: 1;
}

.trip-card-head svg,
.trip-card-foot svg {
  font-size: 1rem;
  margin-right: 0.3rem;
}

.trip-card-head a.link {
  display: flex;
  align-items: center;
  mix-blend-mode: difference;
}

.trip-card-title {
  width: 60%;
  color: var(--secondary-blue);
}

/* ---------------- EXPERIENCE CARD CSS  */

.mySwiper01 {
  padding: 1rem 0 1.5rem;
}

.mySwiper01 .swiper-button-prev {
  display: none;
}

.experience_card {
  padding: 4px;
  border-radius: 16px;
  background: white;
  transition: all 0.25s linear;
}

.experience_card:hover {
  cursor: pointer;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
  margin-top: -2px;
  transition: all 0.25s linear;
}

.experience_card_image {
  border-radius: 1rem;
  height: 192px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.experienceImg01 {
  object-fit: cover;
  width: 100%;
  height: 192px;
  border-radius: 1rem;
}

.experience_card_content {
  padding: 8px 24px 8px 16px;
  margin-top: 1rem;
}

.experience_card_content h4 {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 700;
  color: var(--secondary-blue);
  margin-bottom: 0.5rem;
}
.experience_card_content small{
  font-size: .875em;
  color: var(--secondary-blue);
}
.property-details {
  padding: 4px 24px 16px 16px;
  color: var(--secondary-blue);
}

.experience_card_content h5 {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 500;
  color: var(--secondary-blue);
  margin: 0;
}

.experience_card_content h5 img {
  margin-right: 4px;
  padding-bottom: 2px;
}

/* .experience_card_image_overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 49.63%,
    rgba(0, 0, 0, 0.5) 100%
  );
  padding: 3rem 0 1rem;
} */

.experience_card_det {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.event-bottom {
  border-top: 1px solid #adb4bd75;
  padding: 16px;
}

.experience_card_det .ribbon {
  position: relative;
  display: inline-block;
  margin-left: -8px;
  font-size: 0.8rem;
}

.ribbon {
  position: relative;
  display: inline-block;
  margin-left: -8px;
  font-size: 0.8rem;
}

.card_det svg {
  color: #1c75bc;
}

.card_det small {
  font-size: 12px;
  color: #1c75bc;
}

.experience_card_det .ribbon:before {
  top: 0.3em;
  left: 0.2em;
  width: 100%;
  height: 100%;
  border: none;
  background: #00000030;
  filter: blur(6px);
  z-index: -2;
}

.ribbon > .text {
  display: inline-block;
  padding: 0.524em 1em;
  line-height: 1.2em;
  background: var(--red);
  position: relative;
  color: var(--white);
}

.ribbon > .text svg {
  font-size: 14px;
}

.experience_card_det .duration {
  position: absolute;
  top: 0.5em;
  right: 0;
}

.ribbon:before {
  top: 0.3em;
  left: 0.2em;
  width: 100%;
  height: 100%;
  border: none;
  background: #00000030;
  filter: blur(6px);
  z-index: -2;
}

.ribbon:after,
.bold:before {
  border-width: 1.1em 1em 1.2em 0.5em;
  border-color: #ff5733 transparent #ff5733 #ff5733;
  z-index: 1;
}

.ribbon:after,
.ribbon:before,
.text:before {
  content: "";
  position: absolute;
  border-style: solid;
}

.text:before {
  bottom: 100%;
  left: 0;
  border-width: 0.5em 0.7em 0 0;
  border-color: transparent #d7451a transparent transparent;
}

.guest_booking_details_inner_left img {
  height: 48px;
  border-radius: 16px;
  border: 2px solid #fff;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  margin-right: 8px;
}

.event-bottom button {
  width: 50%;
  border: 1px solid #adb4bd;
  color: #adb4bd;
}

.event-bottom button:hover small {
  color: #fff !important;
}

.event-bottom button small {
  color: #1c75bc;
}

.gct_left .ribbon {
  font-size: 1.2rem;
  margin-left: -1.85rem;
}

.gct_left .ribbon:after {
  border-width: 1.1em 1em 1.25em 0.5em;
}

.gct_left .ribbon .ribbon-small {
  font-size: 0.65rem;
  padding: 0.2rem 1rem;
}

.price_tag {
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  padding: 2px 0.6rem;
  width: 7rem;
  text-align: center;
  color: var(--white);
  background: #ff5733;
  font-family: var(--body-font);
  position: relative;
}

.price_tag:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 1.2em 1em 1.2em 0.5em;
  border-color: #ff5733 transparent #ff5733 #ff5733;
  z-index: 1;
  right: -1rem;
  top: 0;
}

.price_tag h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  color: var(--white);
}

.stay_duration {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: var(--white);
  padding-right: 1rem;
  font-family: var(--body-font);
}

.list-date h6 {
  font-size: 10px;
  line-height: 15px;
  font-weight: 600;
  color: #1c75bc;
}

.property-detaills-page .list-date h6 {
  max-width: 100px;
  gap: 8px;
  display: flex;
  align-items: center;
}

.btn-transperent {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: var(--primary-blue);
  text-transform: uppercase;
  border: 1px solid var(--primary-blue);
  border-radius: 4px;
  padding: 0 4px;
}

.btn-transperent:hover {
  color: var(--white);
  background-color: var(--primary-blue);
}

.dashedBorder {
  margin: 1.1rem 0;
  width: 100%;
  /* height: 1px; */
  border: 1px solid #adb4bd75;
}

.btn-tab h6 {
  font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
  color: #6a778b;
  margin: 0;

  overflow: hidden;
  text-overflow: clip;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.btn-tab {
  background: white;
  max-width: 170px;
  height: 66px;
  text-align: left;
  border-radius: 16px;
  padding: 8px 16px 8px 8px;
  border: 1px solid rgb(15 15 15 / 13%);
}

.btn-tab:hover {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
}

.overflow-x-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.itnery-tab .nav-item a.nav-link {
  font-weight: 400;
  color: var(--secondary-blue);
  font-family: var(--body-font);
  text-transform: capitalize;
  cursor: pointer;
}

.itnery-tab .nav-item.active .nav-link {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.3rem;
}

.ite-date {
  font-size: 1.3rem;
  margin: 1rem;
  color: var(--green);
}

.ite-date small {
  font-size: 0.9rem;
  display: inline-block;
  padding-left: 24px;
  color: var(---dark-green);
}

.tod {
  transform: rotate(90deg);
  min-width: max-content;
  top: 40%;
  position: absolute;
  color: #adb4bd !important;
}

.tod svg {
  margin-right: 0.5rem;
}

.ite-details {
  margin-left: 6rem;
  border-left: 1px dotted #ccc;
  padding-left: 2rem;
  color: var(--secondary-blue);
}

.ite-details p small{
  color: #adb4bd;
}

.ite-sleep {
  margin-top: 1.8rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 16px;
  color: var(--secondary-blue);
}

.ite-sleep svg {
  margin-right: 0.55rem;
}

.ico-24 {
  font-size: 1.88rem;
}

.ico-32 {
  font-size: 2rem;
}

/* -------------------------------- TAB SLIDE CSS */

.tabSwiper {
  padding: 0;
}

.tabSwiper .swiper-slide {
  width: auto !important;
}

.tabSwiper .swiper-wrapper {
  padding: 0 0 0.6rem;
}

.bgPoster {
  height: 343px;
  overflow: hidden;
  border-radius: 16px;
}

.bgPoster:hover {
  cursor: pointer;
  filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.2));
  transition: all 0.25s linear;
  margin-top: -5px;
}

.poster-img {
  border-radius: 16px;
  width: 100%;
  object-fit: cover;
}

.poster-img:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.24);
  z-index: 1;
}

@media (max-width: 900px) {
  .poster-img {
    height: -webkit-fill-available;
    width: 100%;
  }

  .event-bottom {
    padding: 0.5rem;
  }
}

.bgPoster_content {
  display: flex;
  align-items: end;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  padding: 3rem;
  width: 100%;
  z-index: 2;
}

@media (max-width: 1100px) {
  .bgPoster_content {
    padding: 0 3rem;
  }

  .bgPoster {
    margin-bottom: 1.1rem;
  }
}

.bgPoster_content h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  color: var(--white);
  margin: 0;
}

.see-more {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 2px 5px 0px #b9451382;
}

/* SECTION04 CSS START */

#experiences .section04{
  margin-top: 1.5rem;
}

.mySwiper02 .swiper-wrapper {
  margin: 1rem 0 !important;
}

.mySwiper02 .swiper-slide {
  width: auto !important;
}

.btn-tab-tracking {
  padding: 10px 18px;
  border-radius: 20px;
  background-color: var(--white);
  border: 2px solid rgba(173, 180, 189, 0.25);
  color: var(--secondary-blue);
  background: var(--white);
  transition: all 0.25s linear;
  min-width: fit-content;
}

.btn-tab-tracking img {
  filter: grayscale(100%) invert(50%);
  width: 11px;
  margin: 0 4px;
}

.btn-tab-tracking:hover {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
}

.btn-tab-tracking h5 {
  font-size: 0.85rem;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.btn-tab-tracking.active {
  color: var(--white);
  border-color: var(--white);
  background: #ff5733;
}

.btn-tab-tracking.active img {
  filter: grayscale(0%) invert(0%);
}

.experience_card_content p {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: var(--secondary-blue);
  margin: 0;
}

.section04 .experience_card_content h5 {
  color: #0a2753;
}

/* FIFTH CSS SECTION START  */

.section05_heading {
  position: absolute;
  bottom: 4rem;
  z-index: 999;
  left: 3rem;
}

.secondBanner::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.33);
  z-index: 9;
  left: 0;
  top: 0;
}

.section05_heading p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: var(--white);
  font-family: var(--body-font);
}

.secondBanner .secondBannerBg {
  height: 26rem;
  object-fit: cover;
  width: 100%;
  object-position: top;
}

.btn.btn-more {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  color: var(--primary-blue);
  background: white;
  border: 1px solid var(--primary-blue);
  text-transform: uppercase;
}

.btn.btn-more img {
  padding-bottom: 0.2rem;
  margin-left: 0.37rem;
}

.btn.btn-more:hover {
  color: var(--white);
  background: var(--primary-blue);
}

.btn.btn-more:hover img {
  filter: invert(100%);
}

/* SECTION06 CSS START  */

.section06 .title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0.4rem;
}

.activities_wrapper {
  gap: 8px;
}

.activities_card {
  height: 130px;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
}

.activities_card:hover {
  cursor: pointer;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
  margin-top: -2px;
  transition: all 0.25s linear;
}

.activities_card_img::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 35%,
    rgba(0, 0, 0, 0.8) 95.71%
  );
  z-index: 9;
  left: 0;
  top: 0;
}

.activities_card_img img {
  object-fit: cover;
  height: 130px;
  width: 100%;
}

.activities_card_content {
  margin-top: -4.5rem;
  padding: 0 1rem;
  position: relative;
  z-index: 99;
}

.activities_card_content h4 {
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;
  color: var(--white);
}

.activities_card_content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--white);
}

/* safetyfurut css start  */

.safetyfurut {
  padding: 24px;
  background-color: #ffeabe;
}

.safetyfurut h4 {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: 500;
  color: var(--secondary-blue);
  font-family: var(--heading-font);
}

.safetyfurut p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--secondary-blue);
  font-family: var(--body-font);
}

.btn-learn-more {
  background: var(--primary-blue);
  color: var(--white);
  border-color: transparent;
  font-family: var(--body-font);
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: center;
  width: 94px;
}

.btn-learn-more:after {
  opacity: 0.83;
  color: var(--white);
}

/* SECTION08 CSS START  */

.Initiatives_furut_camps {
  padding: 2.5rem;
  border: 1px solid #0000002d;
  background-color: rgba(147, 189, 51, 0.5);
}

.lgTitle h2 {
  font-family: var(--heading-font);
  font-size: 24px;
  font-weight: 500;
  color: var(--secondary-blue);
}

.lgTitle p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  color: var(--secondary-blue);
  font-family: var(--body-font);
}

.card_content {
  padding: 32px;
  margin-bottom: 8px;
}

.card_content_ic {
  margin-bottom: 16px;
}

.card_content h4 {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: 500;
  text-align: left;
  font-family: var(--heading-font);
  color: var(--secondary-blue);
  margin: 1.5rem 0;
}

ol.numberList li {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 1rem;
  color: var(--secondary-blue);
  font-family: var(--body-font);
}

/* SECTION09 CSS START  */

.section09 h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.84px;
  text-align: left;
  color: #323232;
  margin-bottom: 24px;
}

.image_wrapper {
  gap: 32px;
  overflow-x: auto;
  white-space: nowrap;
}

/* SECTION10 CSS START  */

.safety_furutcamp_lt h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  color: var(--secondary-blue);
}

.safety_furutcamp_lt  p{
  color: var(--secondary-blue);
}

/* SECTION11 CSS START   */

.section11 h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16.75px;
  text-align: left;
  color: var(--primary-blue);
  margin: 16px 0;
}

.Camping_details ul li {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #6a778b;
  font-family: var(--body-font);
  border-top: 1px solid #00000020;
  border-bottom: 1px solid #00000020;
  cursor: pointer;
}

.Camping_details ul li:hover {
  color: var(--red);
}

.Camping_details ul {
  list-style: none;
  padding: 0;
}

.second_footer {
  background-image: url("../../assets/images/footer-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 16px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  background-color: #1111117e;
  background-blend-mode: multiply;
}

.second_footer div h4 {
  position: relative;
  padding-left: 7.5rem;
}

.second_footer div h4 strong {
  background-image: url("../../assets/img/num3.svg");
  background-repeat: no-repeat;
  display: inline-block;
  padding: 30px;
  position: absolute;
  left: 3rem;
}

.footer_top {
  margin-bottom: 40px;
  z-index: 2;
}

.logo_circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: white;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 13px;
}

.logo_circle h3 {
  font-family: Impact;
  font-size: 36px;
  font-weight: 400;
  line-height: 37.69px;
  text-align: center;
  color: var(--red);
  margin: 0;
}

.logo_circle span {
  font-family: Impact;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--red);
}

.footer_top h4 {
  font-size: 24px;
  line-height: 25px;
  font-weight: 600;
  text-align: left;
  color: var(--white);
}

.footer_btm {
  margin-bottom: 21px;
}

.footer_btm h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  color: var(--white);
  margin-bottom: 9px;
}

.footer_btm p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #fff6f6;
  margin: 0;
}

/* FOOTER CSS START HERE  */

.footer {
  background: #343841;
}

.footerLogoXl {
  margin-bottom: 32px;
  display: inline-block;
}

.footer_links h5 {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--white);
  margin-bottom: 10px;
}

.footer_links a {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--white);
  display: block;
  margin: 0;
  padding: 0.5rem 1rem 0.5rem 0;
}

.footer_logo_wrapper {
  gap: 80px;
}

.copyright_sec {
  margin-top: 40px;
  gap: 16px;
}

.copyright_sec p,
.copyright_sec a {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
  color: var(--white);
}

/****************************************************************************************** CART PAGE CSS  */

@media (max-width: 900px) {
  .booking-summary-left {
    width: 100%;
  }
}

.booking-summary-left {
  max-width: 616px;
}

.guest_booking_details {
  margin-bottom: 1rem;
}

.breadcrumb-inner li.breadcrumb-item a {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: var(--primary-blue);
  text-transform: uppercase;
  text-wrap: nowrap;
}

.breadcrumb-inner li.breadcrumb-item {
  text-wrap: nowrap;
}

.guest_booking_details_inner {
  padding: 10px 16px;
  border-radius: 1rem;
  background-color: var(--white);
  gap: 10px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
}

.guest_booking_details01 {
  padding: 16px 24px;
  border-top: 1px solid #edeeef;
  border-radius: 0 0 8px 8px;
  width: 94%;
  margin: 0 auto;
  background-color: var(--white);
}

.guest_booking_details_inner_right h5 {
  font-size: 1rem;
  text-align: left;
  font-weight: 700;
  margin: 0;
  color: var(--secondary-blue);
}

.guest_booking_details_inner_right {
  padding-top: 0.4rem;
}

.guest_booking_details_inner_right h6 {
  font-family: var(--body-font);
  font-size: 12px;
  line-height: 1.6;
  font-weight: 600;
  text-align: left;
  color: var(--secondary-blue);
}

.emil-id {
  color: var(--secondary-blue);
  text-transform: capitalize;
}

.phone-num {
  color: var(--secondary-blue);
}

.user-badge {
  font-size: 9px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  color: #6a778b;
  font-family: var(--body-font);
}

.guest_booking_details01 h6 span {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: #6a778b;
}

.guest_booking_details01 h6 {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #adb4bd;
}

.booking_details_title {
  line-height: 1.6;
  font-weight: 500;
  font-family: var(--heading-font);
}

.checkin {
  display: flex;
  gap: 16px;
}

.checkin img {
  align-self: baseline;
  padding: 7px;
}

.checkin span {
  font-size: 16px;
  text-align: left;
  margin-bottom: 1rem;
  display:inline-block;
}

.checkin h6 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  color: var(--secondary-blue);

  font-family: var(--heading-font);
    line-height: 1.6;
}

.booking-summary-box {
  border-radius: 1rem;
  padding: 20px;
  background-color: var(--white);
}

.bfi_card {
  border: 1px solid #adb4bd40;
  padding: 8px 10px;
  border-radius: 10px;
  gap: 10px;
}

.bfi_tag {
  font-size: 10px;
  font-weight: 300;
  line-height: 13px;
  text-align: left;
  color: var(--primary-blue);
  font-family: var(--body-font);
}

.bfi_card_content h6 {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: var(--primary-blue);
  margin: 6px 0;
}

.pickup-type .bfi_card_content h6 {
  margin: 0;
}

.bfi_price {
  font-family: var(--body-font);
  font-size: 9px;
  font-style: italic;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #adb4bd;
}

.bsf_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.6;
  text-align: left;
  color: var(--primary-blue);
  font-family: var(--heading-font);
}

.suggestion_box {
  padding: 20px 10px;
  border-radius: 10px;
  border: 1px dashed #93bd33;
}

.suggestion_box_title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #a4cd39;
  font-family: var(--body-font);
}

.pickup-type .suggestion_box {
  margin-top: 15px;
}

/* .price_head h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #adb4bd;
} */

.price_head h6 span {
  font-size: 28px;
  font-weight: 500;
  color: var(--red);
  margin-top: 4px 0 8px;
  display: inline-block;
  font-family: var(--heading-font);
}

.price_head h6 span svg{
  font-size: 24px;
  margin-right: .7rem;
}

.price_head img {
  width: 30px;
}

button.btn.btn-cancel {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.9px;
  text-align: right;
  color: var(--red);
  font-family: var(--body-font);
}

.ppl-left h6 {
  margin: 0;
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.6;
  color: var(--secondary-blue);
}

.ppl_info {
  margin-top: 4px;
  font-family: var(--body-font);
  font-size: 12px;
  color: #adb4bd;
}

.ppl-center {
  font-family: var(--body-font);
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
  color: var(--secondary-blue);
}

.property-price-lists {
  margin: 24px 0;
  padding: 10px 0;
  border-top: 1px dashed #adb4bd;
}

.ppl-left {
  width: 196px;
}

.ppl_box {
  padding: 0.25rem 0;
  border-bottom: 1px dashed #adb4bd;
}

.price_detils_wrapper .ppl_box:nth-child(2){
  margin-top: 1rem;
  border-top: 1px dashed #adb4bd;
}

.pay-now,
.pay-later {
  border: 1px solid #ccc;
  padding: 4px 16px;
  display: inline-block;
  border-radius: 16px;
  margin-top: 16px;
  border-color: var(--green);
  color: #698a17;
}

.pay-later {
  margin: -6px 0 8px auto;
  border-color: var(--yellow);
  color: var(--yellow);
  text-align: right;
  float: inline-end;
}

.dynamic_price h6 {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  text-align: right;
  color: var(--primary-blue);
}

.dynamic_price h6 span {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: var(--red);
}

.promocode-link {
  margin: 24px 0;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0d6efd;
  font-family: var(--body-font);
  clear: both;
  text-decoration:underline;
}

.booking-summary-right {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 4px;
  background: #e6e6e6;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

/* Hover, active & focus Thumb: Webkit */
input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
}
input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0px 0px 8px 0px #00000014;
}
input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0px 0px 8px 0px #00000014;
}

/* kldsandjkfsn  */

input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 28px;
  width: 28px;
  background-color: #fff;
  border-radius: 50%;
  border: none;

  box-shadow: 0px 0px 8px 0px #00000014;
  transition: 0.2s ease-in-out;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 28px;
  width: 28px;
  background-color: #fff;
  border-radius: 50%;
  border: none;

  box-shadow: 0px 0px 8px 0px #00000014;
  transition: 0.2s ease-in-out;
}

input[type="range"]::-moz-range-progress {
  appearance: none;
  background: #000;
  transition-delay: 30ms;
}

.btn.btn-back {
  font-family: var(--heading-font);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  display: inline-block;
  color: var(--primary-blue);
}

.btn.btn-back img {
  margin-right: 5px;
  width: 10px;
}

.btn.btn-incDes {
  width: 25px;
  border-radius: 4px;
  font-size: 30px;
  padding: 2px;
  line-height: 15px;
  text-align: center;
  color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
}

.bfi_card_wrapper {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 10px;
}

.bfi_actn-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 71px;
}

.bfi_actn-btn h5 {
  color: var(--red);
  margin: 0;
}

/* ********************************************************************************************************************* */
/************************************************************************************************ EXPERIENCE PAGE CSS  */
/* ********************************************************************************************************************* */

.edp01_content_wrapper {
  padding-left: 24px;
  max-width: 592px;
  width: 100%;
}

.highlights_sec {
  gap: 24px;
  padding: 16px;
  border-radius: 10px;
  background-color: var(--white);
}

.pdp_new .highlights_sec {
  gap: 1rem;
}

.highlights_card {
  width: 84px;
  text-align: center;
}

.highlights_card_img {
  width: 84px;
  height: 63px;
  border-radius: 16px;
  border: 1px solid #adb4bd40;
  background-color: var(--white);
  margin-bottom: 1rem;
  box-shadow: 0px 2px 1px 0px #0000001a;
}

.highlights_card_img img {
  width: 28px;
}

.highlights_card_content h6 {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--secondary-blue);
  font-family: "Proza Libre", sans-serif;
}

.epd01_points_wrapper {
  max-width: 544px;
  width: 100%;
  padding: 10px 20px;
  border-top: 1px solid #adb4bd40;
  margin: 20px auto 0;
}

.epd01_ponis {
  margin-bottom: 20px;
}

.epd01_ponis h6 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
  color: var(--primary-blue);
}

.guest_card_box {
  margin: 0 auto;
}

.guest_card_inner {
  border-radius: 16px;
  padding: 24px 16px;
  box-shadow: 0px 8px 10px 0px #0000001a;
  background-color: var(--white);
}

.guest_card_top {
  margin-bottom: 1rem;
}

.guest_card_top .gct_left .ribbon:after {
  border-width: 1.2em 1em 1.25em 0.5em;
}

.stay-details-page .guest_card_top .gct_left .ribbon:after {
  border-width: 1.2em 1em 1.35em 0.5em;
}

.ite-details {
  color: var(--secondary-blue);
}
.ite-details p small{
  color: #ADB4BD;
}

.gct_right h6 {
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  color: #575a8980;
}

.guest_card_box.active{
  position: fixed;
  top: 0;
  z-index: 1024;
  width: 93%;
  left: 5%;
  display: block;
}

.list-group-item svg {
  font-size: 20px;
  margin-right: 1.4rem;
  color: var(--red);
}

.list-group-item p {
  color: var(--secondary-blue);
}

.navbar-toggler {
  border: none !important;
}

/* ************************************************ DROPDOWN BUTTON  */

.btn.dropdown-btn,
.guest_input input {
  border: 1px solid #abacc4;
  border-radius: 8px;
  background-color: transparent;
  color: #c4c4c4;
  display: inline-block;
  width: 100%;
  font-family: var(--body-font);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  padding: 12px;
}

.btn.dropdown-btn:active {
  color: #919191;
  background-color: transparent;
}

.number_ofGuest_card label {
  font-family: var(--body-font);
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  background-color: var(--white);
  padding: 1px 10px;
  position: absolute;
  top: -10px;
  z-index: 9;
  left: 14px;
}

.dropdown-menu {
  padding: 16px;
  border-radius: 16px;
  width: 100% !important;
  background-color: var(--white);
  box-shadow: 0px 4px 4px 0px #0000001a;
  border: none;
}

.dropdown-header {
  font-family: var(--heading-font);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: var(--primary-blue);
  padding: 0;
  margin-bottom: 16px;
}

.dropdown-item {
  padding: 0;
}

.dropdown_list {
  margin-bottom: 16px;
}

.dropdown_list_right {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 80px;
}

.decrease {
  color: #323232;
  font-weight: 700;
  font-size: 18px;
  width: 22px;
  text-align: center;
}

.numbers {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
  color: var(--red);
  font-family: var(--body-font);
}

.decrease:first-child {
  border-right: 1px solid #c4c4c4;
}

.decrease:last-child {
  border-left: 1px solid #c4c4c4;
}

.dropdown_list_left h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--primary-blue);
}

.dropdown_list_left p {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  color: #c4c4c4;
}

.btn-sm.btn-transperent {
  border-radius: 4px;
  padding: 8px;
  background-color: transparent;
  color: #212529;
  font-family: var(--heading-font);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  border: 1px solid #212529;
  text-transform: uppercase;
  transition: 0.3s ease;
}

.btn-sm.btn-transperent:hover {
  background-color: #212529;
  color: #fff;
}

.btn-done {
  border-radius: 4px;
  padding: 8px;
  background-color: var(--red);
  color: var(--white);
  font-family: var(--heading-font);
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.btn-done:hover {
  color: var(--white);
  background-color: var(--red);
}

/* ************************************************************ STAY DETAILS CSS  */

.stay-details-page .guest_card_inner {
  margin-top: -5rem;
  z-index: 9;
  position: relative;
}

@media (max-width: 575.98px) {
  .mobile-bottom-wrapper {
    display: flex !important;
    position: fixed;
    bottom: 1rem;
    z-index: 999;
    width: 100%;
    left: 0;
  }

  .mobile-bottom {
    background-color: var(--white);
    filter: drop-shadow(1px 0 4px rgba(0, 0, 0, 0.2));
    padding: 1rem;
    margin: auto;
    border-radius: 1rem;
  }

  .ite-details {
    margin-left: 2rem;
    padding-left: 1rem;
  }

  .tod {
    left: -48px;
  }

  .modal-content {
    height: 100%;
  }

  button.filter-btn.w-100 {
    display: flex !important;
    position: fixed;
    bottom: 1rem;
    display: inline-block;
    vertical-align: middle;
    background-color: var(--white);
    padding: 1rem;
    left: 15%;
    border-radius: 3rem;
    z-index: 999;
    border: 1px solid var(--grey-0);
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.25));
    width: 70% !important;
  }

  .pd01_top_wrapper .swiper-wrapper .swiper-slide{
    width: 360px !important;
    min-height: 360px;
    border-radius: 8px;
    margin-right: 0.1rem;
}

  
}

.pd01_top_wrapper .swiper-wrapper .swiper-slide{
  border-radius: 8px;
}

.pd01_bottom_content {
  padding-top: 16px;
}

.stay-details-page .pd01_content h4 {
  margin: 16px 0;
}

.btn_cutom_atn:first-child {
  margin-right: 24px;
}

.btn_cutom_atn svg{
  font-size: 14px;
}

.sdp_about p {
  font-family: var(--heading-font);
  font-size: .875em;
  font-weight: 400;
  text-align: left;
  color: #6a778b;
  margin-bottom: 24px;
}

.sdp_about p span{
  font-size: 20px;
  color: var(--secondary-blue);
}

.sdp_highlights p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--primary-blue);
  margin-bottom: 16px;
}

.sdp_highlights p:last-child {
  margin-bottom: 0;
}

.sdp_highlights p img {
  margin-right: 6px;
}

.sdp_amentities .title {
  margin-bottom: 8px;
}

.amentities_card {
  width: 48%;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--white);
  margin-bottom: .25rem;
}

.amentities_card_content h5 {
  font-size: 0.87rem;
  line-height: 1.1;
  font-weight: 400;
  margin: 0;
  text-align: left;
  color: #6a778b;
  font-family: var(--body-font);
}

.message_box_card {
  padding: 1.25rem;
  /* border: 1px solid #00000014; */
  border-radius: 1rem;
  background-color: var(--white);
}

.message_box_owner_img {
  width: 69px;
  height: 69px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.message_box_owner_img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.message_box_owner_content {
  padding-left: 15px;
}

.message_box_owner_content h5 {
  font-size: 16px;
  margin-top: 8px;
  line-height: 1;
  font-weight: 500;
  color: var(--secondary-blue);
}

.message_box_owner_content p {
  font-size: 12px;
  color: #6a778b;
}

.btn.btn-msg {
  background-color: #93bd33;
  color: var(--white);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #ffffff40;
}

.btn.btn-msg:hover {
  background-color: #94bd33e8;
  color: var(--white);
}

.message_box_card_footer {
  padding-top: 10px;
  gap: 10px;
}

.message_box_card_footer h6 {
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 500;
  margin: 1rem 0;
  color: var(--secondary-blue);
  font-family: var(--heading-font);
}

.message_box_card_footer p {
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 400;
  color: var(--primary-blue);
  font-family: var(--body-font);
}

.cohost_sec {
  gap: 10px;
}

.cohost_card_img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cohost_card_img img {
  object-fit: cover;
  height: 100%;
}

.cohost_card h5 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 16px;
  margin-left: 10px;
  color: var(--primary-blue);
  font-family: var(--heading-font);
}

.cohost_sec {
  padding-left: 25px;
}

.guest_input label {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
}

.guest_input input {
  padding: 12px 6px;
}

.btn.btn-custom_dark {
  background-color: #6a778b;
  color: var(--white);
  border: 1px solid var(--primary-blue);
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  font-family: var(--heading-font);
}
.btn.btn-custom_dark:active {
  color: var(--white);
  background-color: #6a778b;
}
.bdd-conf_rgt_card {
  text-align: center;
  border: 1px dotted #0c63e4;
  padding: 1rem;
  border-radius: 16px;
  margin: auto;
}
.bdd-conf_rgt_card p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  color: var(--secondary-blue);
}
.bdd-conf_rgt_card h6 {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 500;
  margin: .5rem 0;
  color: var(--secondary-blue);
  font-family: var(--heading-font);
  word-wrap: break-word;
}
.bookingdate {
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
  color: #6a778b;
  margin-top: 8px;
  text-transform: uppercase;
}
.bdd-conf_lft_txt h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
.bdd-conf_lft_img svg {
  font-size: 56px;
  fill: #198754;
}
.bdd-conf_lft_txt p {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  color: var(--secondary-blue);
}
.bdd-digital, .bdd-conf, .bdd-property {
  padding: 0 15px;
}
.bdd-digital p {
  color: var(--secondary-blue);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
}
.bdd-property_lft {
  max-width: 326px;
  gap: 16px;
}
.bdd-property_lft p {
  color: var(--secondary-blue);
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  text-transform: capitalize;
}
.ico-label {
  display: inline-block;
  font-size: 1em;
  font-family: var(--body-font);
  font-weight: 600;
  opacity: .4;
}

.property-card-wrapper h6{
  font-family: var(--heading-font);
  color: var(--secondary-blue);
}
.ico-label svg{
  margin-right: .5rem;
}
.userDet h5, .userDet h6{
  color: var(--secondary-blue);
  font-family: "Proza Libre", sans-serif;
  text-transform: capitalize;
}
.bdd-property_lft h5 {
  color: var(--primary-blue);
  font-size: 16px;
  font-weight: 600;
  line-height: 21.72px;
}

.stay-list-item-details small{
  color: var(--secondary-blue);
}
.bdd-property_lft h3 {
  font-size: 28px;
  line-height: 1.6;
  font-weight: 500;
  color: var(--secondary-blue);
}
.bdd-property_lft h6 {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 500;
  color: var(--secondary-blue);
}
.bdd-bkgdtl {
  padding: 24px 24px;
}
.bdd-bkgdtl_lft p {
  display: flex;
  align-items: center;
  color: #adb4bd;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  gap: 8px;
  margin-bottom: 10px;
}

.bdd-bkgdtl_lft p svg {
  font-size: 0.8rem;
}
.bdd-bkgdtl_lft h5 {
  color: var(--primary-blue);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 8px;
}
.booking-details-body .property-price-lists {
  border-top: none;
}
.booking-details-body .ppl-left {
  width: auto;
}

.camp-rules-card-inner ul li {
  font-family: var(--body-font);
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  text-align: left;
  padding: 0.5rem 1rem;
  list-style: none;
  border-bottom: 1px solid #00000020;
}
.camp-rules-card-inner ul {
  padding-left: 0;
}
.camp-rules-card h5 {
  font-size: 1.5rem;
  color: var(--secondary-blue);
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 8px;
}
.booking-details-body .card_content {
  padding: 12px;
}
/* ************************************************************************************************************ */
/****************************************************************************************** MY TRIP PAGE CSS  */
/* ************************************************************************************************************ */
.upcoming-trips-head {
  margin-bottom: 32px;
}

.trip_timer {
  font-family: var(--heading-font);
  font-size: 14px;
  color: var(--secondary-blue);
}
.trip_timer svg {
  font-size: 14px;
}
.utr_trip_card_inner {
  margin: 8px 0 0;
  padding: 24px;
  border-radius: 16px;
  gap: 1rem;
  box-shadow: 0px 1px 4px 0px #00000040;
  margin-bottom: 1rem;
  background-color: #fff;
}
.utr_card_head h6,
.btn-share {
  font-family: var(--body-font);
  font-size: 14px;
  text-align: left;
}

.utr_card_head h6 svg{
  font-size:14px;
}

.btn-share svg {
  font-size: 12px;
}

.utr_card_footer p {
  font-size: 14px;
  color: var(--primary-blue);
  text-transform: capitalize;
  margin:0;
}
.utr_card_footer p svg{
  font-size:14px;
}
.utr_card_footer p img,
.btn-share img {
  margin-right: 8px;
}
.upcoming-trips-footer .upcoming-trips-lft h5 {
  rotate: 0deg;
}
.upcoming-trips-lft h6{
  font-size:1rem;
  font-weight: 500;
  font-family: var(--heading-font);
  line-height: 1.6;
}
.upcoming-trips-footer .utr_trip_card_inner {
  padding: 0 0 15px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: none;
  border: 1px solid #0000002d;
}
.utr_card_center h3 {
  font-size: 20px;
  line-height: 1.6;
  font-weight: 500;
  text-align: left;
  color: var(--secondary-blue);
  cursor: pointer;
}

.trip_location_poster img {
  height: 318px;
  object-fit: cover;
  object-position: center;
}

.utr_card_center img {
  width: 100px;
}
.upcoming-trips-footer .utr_card_head,
.upcoming-trips-footer .utr_card_center {
  padding: 0 1rem;
}

.bcp02_rtl button svg{
  font-size: 20px;
  margin-right: 7px;
}

.tabBtnWrapper{
  margin: 2rem 0;
}
/* ************************************************************************************************************ */
/****************************************************************************************** RESPONSIVE CSS  *****/
/* ************************************************************************************************************ */
@media (max-width: 600px) {
  .subtitle {
    font-size: 15px;
    line-height: 22px;
  }
  .bdd-conf_rgt_card,
  .bdd-property_rgt,
  .property-price-lists-head p,
  .bcp02_rtl {
    margin-top: 1rem;
  }
  .bdd-digital_img,
  .utr_trip_card {
    margin-bottom: 1rem;
  }
  .bdd-bkgdtl_lft,
  .camp-rules-card {
    margin-bottom: 24px;
  }

  .pd01_bottom_wrapper .pd01_bottom_thumbnails01 {
    background-color: rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    margin-right: 1rem;
    margin-top: -6.3rem;
    padding: 0.3rem;
    z-index: 9;
  }

  .pd01_bottom_wrapper .pd01_bottom_thumbnail01_inner {
    width: 56px;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 0.3rem;
  }

  .pd01_bottom_wrapper .pd01_bottom_thumbnail01_inner img {
    width: 56px;
    height: 56px;
  }

  .guest_card_box {
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 1024;
  }

  .tabBtnWrapper {
    overflow-x: auto;
  }

  .furutcamps-logo img {
    width: 130px;
}
}
@media (max-width: 950px) {
  .utr_trip_card_inner,
  .upcoming-trips-footer .utr_trip_card_inner {
    width: auto;
  }

  button.nav_btn span {
    text-wrap: nowrap;
  }
}

.editModal .form-label {
  font-family: var(--body-font);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--primary-blue);
}

.editModalPopup .modal-content {
  padding: 2rem;
  background-color: var(--white);
  box-shadow: 0px -4px 10px 0px #0000001a;
}

.editModal .form-control {
  border: 1px solid #575a8966;
}

h5.modal-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-align: left;
  color: #6a778b;
  font-family: var(--heading-font);
}

.editModalPopup .modal-body,
.editModalPopup .modal-header {
  padding-left: 0;
  padding-right: 0;
}

.stays_card_wrapper:hover {
  cursor: pointer;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.3));
  margin-top: -2px;
  transition: all 0.25s linear;
}

.stays_card_content {
  bottom: 2.4rem;
  position: relative;
}

.scc_top {
  width: fit-content;
  margin: auto;
  background-color: var(--white);
  border-radius: 8px;
  padding: 12px 24px;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
}

.scc_top_left img {
  width: 24px;
}

.scc_top_right h6 {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 700;
  color: var(--secondary-blue);
  margin: 0;
}

.scc_top_right p {
  font-size: 0.9rem;
  font-weight: 700;
  margin: 0;
  color: var(--red);
}

.scc_top_right p span {
  font-weight: 400;
  color: #8b8b8b;
}

.scc_top_right p svg {
  width: 1rem;
  color: #8b8b8b;
}

.stays_card_content small {
  font-size: 0.7rem;
  line-height: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--red);
  display: block;
  margin-top: 0.8rem;
}

.stays_card_content p {
  display: flex;
  text-align: center;
  margin-top: .5rem;
  font-weight: 600;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
}

.stays_card_content p small{
  margin: 0;
  color: var(--secondary-blue);
}

/* PD04 CSS START  */

.bd-dashed {
  margin: 24px 0;
  width: 100%;
  height: 1px;
  border: 1px dashed #adb4bd;
}

.pd04_left_content h6 {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #6a778b;
  font-family: var(--heading-font);
}

.pd04_left_content h6 span {
  font-weight: 500;
}

.pd04_left_content p {
  font-size: 16px;
  color: var(--primary-blue);
}

.icwtxt {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  gap: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-family: var(--heading-font);
}

.icwtxt svg, .icwtxt img{
  width: .8rem;
} 

/* .icwtxt img {
  width: 1rem;
} */

.pd04_right_content {
  margin-bottom: 32px;
}

.pd04_right_content h5 {
  font-family: var(--heading-font);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  color: #adb4bd;
  margin-bottom: 8px;
}

.btn-no {
  background: var(--primary-blue);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #ffffff40;
}

.btn-no:hover {
  color: var(--white);
  background: #575a89dc;
}

.rounded-top h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: var(--primary-blue);
}

.rounded-top p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.pd01_bottom_thumbnail01_inner {
  width: 100px;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 0.3rem;
}

.pd01_bottom_thumbnail01_inner img {
  height: 100px;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.5));
  width: 100px;
  border-radius: 1rem;
  overflow: hidden;
  object-fit: cover;
}

.rating_txt {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  border: 2px solid #ffffff40;
  background-color: #a4cd39;

  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;
  text-align: center;
  color: var(--white);
  font-family: var(--body-font);
}

.ratings01 {
  margin: 0 16px 0px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--primary-blue);
}

.pd01_bottom_thumbnails01 {
  background-color: rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  margin-right: 24px;
  margin-top: -7.3rem;
  padding: 0.6rem;
  z-index: 9;
}

.btn_cutom_atn img {
  padding-bottom: 2px;
  margin-right: 10px;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  color: var(--secondary-blue);
}

.breadcrumb .breadcrumb-item + .breadcrumb-item a,
.breadcrumb .breadcrumb-item + .breadcrumb-item,
.breadcrumb .breadcrumb-item a {
  font-size: 14px;
  color: var(--secondary-blue);
}

.pd01_top_wrapper .swiper.mySwiper{
  margin-left:0;
  margin-right:0;
}

.pd01_content h4 {
  font-size: 32px;
  font-weight: 600;
  color: var(--primary-blue);
  margin: 1.5rem 0 .5rem;
}

.pd01_content h6{
  font-family: var(--heading-font);
  line-height: 1.6;
  color: var(--secondary-blue);
}

.pd01_svg svg {
  font-size: 25px;
  margin-right: 0.8rem;
}

.frt-badge img {
  margin-bottom: 1rem;
  filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.25));
}

.frt-badge h5 {
  font-size: 0.6rem;
  text-transform: uppercase;
  white-space: pre-wrap;
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .booking-summary-left {
    max-width: 100%;
  }
}

.modal-dialog.popupModal.modal-lg.modal-dialog-centered {
  width: 666px;
}

.userProfile img {
  width: 56px;
  height: 56px;
  border-radius: 16px;
  border: 3px solid #ffffff40;
  box-shadow: 0px 4px 6px 0px #00000040;
  overflow: hidden;
  object-fit: cover;
}

.serviceCardWrapper {
  gap: 11px;
  background-color: var(--white);
  border-radius: 16px;
  /* border: transparent; */
  padding: 1.5rem;
}
.serviceHead h6{
  font-family: var(--heading-font);
}
h5.serviceCardTitle {
  font-family: var(--heading-font);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: var(--primary-blue);
}
.serviceDesc {
  color: #6a778b;
  font-family: var(--body-font);
  font-size: 13px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
}
.servicePrice {
  font-family: var(--body-font);
  font-weight: bold;
    color: var(--red);
    font-size: 1rem;
}
.servicePrice svg{
  width: 1.1rem;
}
.serviceCardWrapper small{
  font-size: 0.8rem;
}
.btnCstm {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 80px;
}

.btn-done:hover {
  color: var(--white);
  background-color: #f24720;
}

/* ///////////////////////////////////////////////////My Account Layout Css ///////////////////////////////////*/
.profile-info-inner {
  margin-top: 1rem;
  padding: 32px;
  border-radius: 10px;
  background-color: var(--white);
}
.profile_box {
  margin-bottom: 0.8rem;
  padding: 10px 10px 20px 10px;
}
.pi-title {
  font-family: var(--heading-font);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--secondary-blue);
}
.pi-subtitle {
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 1.4;
  font-style: italic;
  font-weight: 400;
  color: #698a17;
}
button.nav_btn {
  padding: 16px 18px;
  border-radius: 8px;
  border: none;
  background-color: var(--white);
  border: none;
}

button.nav_btn svg {
  font-size: 20px;
  color: var(--secondary-blue);
}

button.nav_btn:hover {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
}
button.nav_btn.active {
  cursor: pointer;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
  margin-top: -2px;
  transition: all 0.25s linear;
}
button.nav_btn span {
  font-family: var(--heading-font);
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: var(--primary-blue);
  margin-top: 8px;
  display: inline-block;
}
.host-user-img img {
  height: 56px;
  width: auto;
  border: 3px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  object-fit: cover;
}
.host-user-img {
  width: 56px;
  height: 56px;
}
button.btn.btn-main {
  font-family: var(--heading-font);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: var(--primary-blue);
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid var(--primary-blue);
  text-transform: uppercase;
}
.myAccount_form input {
  border-radius: 10px;
  padding: 12px 12px 12px 49px;
  border-color: #575a8980;
}
.myAccount_form textarea {
  border-radius: 10px;
  padding: 12px 12px;
  border-color: #575a8980;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #ffa592;
  outline: 0;
  box-shadow: none;
}
.myAccount_form label.formLabel {
  background: white;
}
.myAccount_form .inputIc {
  bottom: 0.8rem;
}

.myAccount_form2 {
  padding: 40px;
  border-radius: 10px;
  background-color: var(--white);
}
.piw-footer {
  margin-top: 24px;
}
.piw-footer-card {
  padding: 20px 0 0;
}
.piw-card {
  margin-top: 10px;
  padding: 30px;
  border-radius: 10px;
  background-color: var(--white);
}

.piw-card p {
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--primary-blue);
}
.piw-card h5 {
  font-family: var(--heading-font);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: var(--primary-blue);
}
@media (max-width: 666px) {
  .profile-info-wrapper {
    max-width: 100%;
  }
  .myAccount_form2 {
    padding: 40px 20px;
  }

  .addGuest{
    width: 100%;
  }

  .guest_card_box{
    display:none;
  }

  .guest_card_box.active{
    position: fixed;
    top: 0;
    z-index: 1024;
    width: 93%;
    left: 5%;
    display: block;
}
}

.number-input {
  width: 125px;
  align-items: end;
}

.review_card_txt h5 {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0.4rem;
  font-weight: 600;
  color: var(--primary-blue);
  font-family: var(--body-font);
}

.review_card_txt h6 {
  font-family: var(--body-font);
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  color: #6a778b;
}

.review_card P {
  margin-top: 0.8rem;
  font-size: 14px;
  line-height: 26px;
  font-weight: 300;
  color: var(--primary-blue);
  font-family: var(--body-font);
}

.campus_rules_card {
  padding: 20px;
  border-radius: 16px;
  /* border: 2px solid #e0e0e0; */
  background-color: var(--white);
}

.btn-campusRules {
  border-radius: 50%;
  min-width: 3rem;
  min-height: 3rem;
  border: 2px solid var(--white);
  filter: drop-shadow(0 2px 4px var(--grey-0));
  padding: 8px;
  margin: 8px;
  background: #0dcaf0;

  display: flex;
  justify-content: center;
  align-items: center; 
}

.btn-campusRules svg {
  width: .8rem;
  fill: #474747;
}

.btn-campusRules:hover {
  transform: scale(1.1);
  transition: transform 0.2s linear;
}

.btn-campusRules:hover svg {
  fill: var(--white);
}

.campus_rules_card p {
  font-family: var(--body-font);
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  text-align: left;
  color: #8b8b8b;
  max-width: 85%;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .campus_rules_card p {
    max-width: 80%;
  }
}

.campus_rules_card h5 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: #2d9cdb;
  margin-bottom: 0.5rem;
  font-family: var(--heading-font);
}

.campus_rules_card h5 svg{
  font-size: 20px;
  margin-right: .4rem;
}

.campus_rules_card1 h5 {
  color: var(--red);
}

.campus_rules_card1 h5 {
  color: var(--red);
}

.campus_rules_card1 .btn-campusRules {
  background-color: var(--red);
}

.stays_card_image img {
  height: 10rem !important;
  object-fit: cover;
  border-radius: 1rem;
}

.safety_furutcamp {
  padding: 2.5rem;
}

.pd01_top_wrapper .swiper-wrapper .swiper-slide img {
  height: 360px;
  object-fit: cover;
  width: 100%;
}

.pd01_top_wrapper .swiper-wrapper .swiper-slide {
  min-height: 360px;
  width: 360px !important;
  overflow: hidden;
}

.card.bkn-frm-card.p-4.ms-4.me-1.mt-4.sticky-top .d-flex.justify-content-evenly.mb-3.gap-1 .form-floating{
  width:50%;
}

@media (min-width: 1200px) {
  .secondBannerImg {
    height: 669px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.offcanvas.offCanvas1.offcanvas-top.fade.show {
  height: 100vh !important;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  max-width: 1440px;
  margin: 0 auto;
}

.tile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tile:nth-child(1) {
  grid-column: span 4;
  grid-row: span 2;
}

.tile:nth-child(2),
.tile:nth-child(3) {
  grid-column: span 2;
}

.tile:nth-child(4),
.tile:nth-child(5) {
  grid-column: span 3;
}

/* BOOKING PAGE CSS */

.stay-list {
  padding-left: 24px;
  padding-bottom: 16px;
}

.stay-list-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--grey-0);
  padding: 0.5rem 1rem;
  border-radius: 16px;
  min-width: fit-content;
  background-color: var(--white);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
}

.stay-list-item .stay-list-item-img {
  height: 56px;
  width: 56px;
  margin-left: -40px;
  margin-right: 16px;
  border: 2px solid #edeeef;
  border-radius: 8px;
}

.stay-list-item small {
  font-size: 0.75rem;
}

.stay-list-item .chck-in h6{
  font-family: var(--heading-font);
}

.stay-list-item-right {
  display: flex;
  justify-content: space-between;
  min-width: 8rem;
}

.btn-outline-danger {
  --bs-btn-bg: transparent;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
  --bs-btn-color: var(--red);
  font-size: 0.8rem;
}

.stay-list-item-right .form-control {
  width: 4rem;
}

.price-range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #0d6efd;
  cursor: pointer;
  margin-top: -2px;
}

.price-range-slider input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #0d6efd;
  cursor: pointer;
}

.price-range-slider input[type="range"]::-ms-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #0d6efd;
  cursor: pointer;
}

.form-floating .react-datepicker-wrapper {
  width: 100%;
  display: block;
}
.floatLabel .form-floating > label {
  padding: 0;
  left: 0.7rem;
  top: 0.2rem;
}
.react-datepicker-popper {
  z-index: 9;
}
.cstmInp {
  padding-top: 1.625rem !important;
  padding-bottom: 0.625rem !important;
}

.exp_evt svg {
  color: var(--secondary-blue);
}

.exp_evt h4, .exp_evt1 h4{
  color: var(--secondary-blue);
}

.nav-tabs .nav-link:hover {
  border: none;
  transform: scale(1);
}

.nav-tabs .nav-link:focus {
  border: none;
}

.ite-details svg {
  font-size: 18px;
  color: var(--secondary-blue);
  margin-right: 0.3rem;
}

.ite-details i {
  color: #adb4bd;
}

.ite-details p {
  color: var(--secondary-blue);
}

.experience-card {
  padding: 4px;
  border-radius: 16px;
}

.experience-card figure {
  border-radius: 16px;
  min-height: 192px;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

.experience-card figure img{
  width: 80px;
}

.experience-card .property-details {
  padding: 8px 24px 8px 16px;
  color: var(--secondary-blue);
}

.text-light {
  color: #adb4bd !important;
}

.searchResult {
  background-color: #575a89;
}

.modal-dialog.search-modal.modal-dialog-centered {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.searchResult .filter-btn {
  margin: 2.5rem 0;
  width: 40%;
  padding: 8px 16px;
}

.searchResult .search_sec h5,
.searchResult .search_sec p {
  margin: 0;
  line-height: 1.6;
}

.search_sec svg {
  font-size: 24px;
  margin-left: 0.8rem;
  color: var(--secondary-blue);
}

.searchResult .nav-item .active {
  padding: 0.5rem 1rem;
  color: var(--white);
}

.searchResult .nav-link {
  padding: 0.5rem 1rem;
  color: #adb4bd;
  text-transform: capitalize;
}

@media (max-width: 575.98px) {
  .searchResult .filter-btn {
    display: flex !important;
    position: fixed;
    bottom: 1rem;
    display: inline-block;
    vertical-align: middle;
    background-color: var(--white);
    padding: 1rem;
    left: 15%;
    border-radius: 3rem;
    z-index: 999;
    border: 1px solid var(--grey-0);
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.25));
    width: 70%;
  }

  .search-modal .modal-content {
    height: 100%;
  }

  .hero_section .swiper-slide,
  .trip-card {
    max-width: 320px;
  }
}

.bkng-detls-stay {
  width: 100%;
}

.number-input #floatingInputGroup1 {
  width: 43px;
  text-align: center;
  border-color: #6c757d;
  padding: 4px 8px;
}

.number-input label {
  font-size: 12px;
  margin-bottom: 4px;
  display: inline-block;
}

.number-input .input-group .btn {
  padding: 4px 8px;
}

.number-input .input-group .btn:hover{
  background: var(--red);
}

.user-badge small {
  color: var(--red);
  font-size: 0.8rem;
}

.user-badge small svg{
  font-size: 0.7rem;
}

#toc_head1 h4,
#toc_head1 p,
#toc_head2 h4,
#toc_head2 p {
  color: var(--secondary-blue);
}

/* ABOUT US PAGE CSS  */

.about-page {
  background-color: #f5f5f5;
}

.hero-about .container {
  background-image: url("../img/camping-about-ani.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding: 12rem 16rem 2rem 16rem;
  margin-bottom: 9rem;
}

.about04 {
  padding: 5rem 0;
}

.teamMember {
  padding: 0 13rem;
}

.team-member {
  background-color: var(--white);
  padding: 0.5rem;
  border-radius: 8px;
  border: 2px solid var(--grey-0);
  margin-right: 0.5rem;
}

.team-member .team-member {
  margin: 0 0 1rem;
}

.team-member .team-member-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 16px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.4));
  height: 150px;
}

.team-member .team-member .team-member-caption {
  padding: 0 0.2rem;
}

.team-member {
  font-family: var(--heading-font);
  line-height: 1.6;
  color: var(--secondary-blue);
}

.about05,
.about06 {
  padding: 8rem 0;
}

.join-card {
  padding: 0.5rem;
  border-radius: 16px;
  margin: 16px;
}

.join-card img {
  border-radius: 8px;
}

.join-card h4,
.join-card small,
.join-card p {
  color: var(--secondary-blue);
}

.about07 img {
  height: 14rem;
  width: 100%;
  border-radius: 16px;
  border: 4px solid var(--grey-0);
  margin-bottom: 5rem;
  object-fit: cover;
}

.values-about {
  margin: 12rem 0 5em 0;
}
.culture-value-img {
  width: 50%;
}
.culture-value-img img {
  height: 7rem;
  width: 100%;
  border-radius: 1rem;
  object-fit: cover;
}

.values .nav-tabs .nav-item .nav-link {
  color: var(--primary-blue);
  text-transform: none;
  font-weight: 600;
  font-size: 1.05rem;
  text-align: right;
  cursor: pointer;
}

.values .nav-tabs .nav-item.active {
  display: inline-block;
  border: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.11);
  border-radius: 32px;
  color: var(--primary-blue);
  background-color: white;
}

.values .list-group.value-list {
  background: none;
}

.values .value-item {
  background: none;
  border: none;
}

.tab-pane h5 svg {
  font-size: 1rem;
  margin-right: 0.69rem;
}

@media (max-width: 1200px) {
  .teamMember {
    padding: 0 7rem;
  }
}

@media (max-width: 991px) {
  .teamMember {
    padding: 0;
  }

  .join-card {
    margin: 0;
  }

  .guest_card_box {
    margin: 3rem auto 0;
  }

  .bkn-frm-card {
    margin-top: 1rem;
  }
}

@media (max-width: 551px) {
  .search-bar {
    margin-top: 0;
  }

  .ribbon:after {
    border-width: 1.2em 1em 1.12em 0.5em;
  }

  .activities_card_content {
    margin-top: -6rem;
  }

  .hero-about .container {
    padding: 3rem 0;
  }

  .col11 .nav-item a {
    padding: 0 0.5rem;
  }

  .Initiatives_furut_camps {
    padding: 1rem;
  }

  .Camping_details {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 0.3rem;
  }

  .serviceCardWrapper,
  .campus_rules_card {
    margin-bottom: 1rem;
  }

  .bkn-frm-card {
    margin-top: 0;
    display: none;
  }

  .guest_card_top .gct_left .ribbon:after {
    border-width: 1.155em 1em 1.35em 0.5em;
  }

  .ribbon:after,
  .bold:before {
    border-width: 1.15em 1em 1.18em 0.5em;
  }

  .guest_card_box.sticky-top.d-md-block.active {
    display: block;
    position: fixed;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
    border-radius: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    opacity: 1;
    height: max-content;
    z-index: 1025;
  }

  .stay-list-item {
    display: block;
  }

  .pd01_bottom_wrapper.row {
    flex-direction: column-reverse;
  }

  .guest_card_box.sticky-top.d-md-block.false {
    position: unset;
    display: none;
  }
}

.le-cnt {
  height: 100vh;
  background-size: cover;
  background-position: center right;
}

.furut-mono {
  /* background-image: url(../media/svg/FURUT-MONOGRAM.svg); */
  background-repeat: no-repeat;
  background-size: contain;
}

.login-page {
  background-image: url("../img/login-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  height: 100vh;
  padding: 2rem 0;
}

.le-cnt {
  height: 100vh;
  background-size: cover;
  background-position: center right;
}

.journal {
  opacity: 55%;
}

.text-truncut {
  overflow: hidden;
  text-overflow: clip;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div#loom-companion-mv3 {
  display: none !important;
}
.btn-tab-img img {
  width: 48px;
  height: 48px;
  object-fit: cover;
}

 .bdd-conf_lft_img svg.text-warning {
  fill: #ffc107 !important; 
}

.bdd-conf_lft_img svg.text-info {
  fill: #0dcaf0 !important; 
}

.bdd-conf_lft_img svg.text-danger {
  fill: #ff5733 !important; 
}

.login-page .btn-social {
  padding: 15px 20px;
  background: #FFFFFF;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  margin: .5rem;
}

.pd01 {
  margin-bottom: 5rem;
}

/* .booking-confirmation-page .section08 .Initiatives_furut_camps.br16{
  display: none !important;
} */

