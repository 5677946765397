.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.experience-cards {
  gap: 2px; /* Optional: Adjust spacing between cards */
}
.html {
  scroll-behavior: auto;  /* No smooth scroll, make it instant */
}
