/* INDEX  CSS START DON'T USE THIS CSS CODE THAT IS WRITTTEN IN THI BLOCK */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5 !important;
}

a{
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, button, .btn{
  font-family: "Proza Libre", sans-serif;
}

.btn{
  width: max-content;
}

a.btn.btn-outline-warning:hover{
  color: var(--white);
}

h6, span, p{
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

.br16{
  border-radius: 16px !important;
}

.bgDanger{
  color: white !important;
  background-color: #FF5733 !important;
}

.card-shadow{
  box-shadow: 0px 5px 10px 0px #0000001A;
}

/* INDEX CSS START DON'T USE THIS CSS CODE THAT IS WRITTTEN IN THI BLOCK */

.rating_txt {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  border: 2px solid #ffffff40;
  background-color: #a4cd39;

  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;
  text-align: center;
  color: var(--white);
  font-family: "Open Sans", sans-serif;
}

.ratings01 {
  margin: 0 16px 0px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #575a89;
}

.btn_cutom_atn img {
  padding-bottom: 2px;
  margin-right: 10px;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: url("../../assets/img/breadcrumb.png");
}

/* ------------------------------------------------------------------------------section03 CSS  */

.stays_card_image {
  border: 2px solid #d5d5d580;
  border-radius: 19px;
}

/* PD04 CSS START  */

.bd-dashed{
  margin: 24px 0;
  width: 100%;
  height: 1px;
  border: 1px dashed #ADB4BD;
}

.pd04_left_content h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: left;
  color: #6a778b;
}

.pd04_left_content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #575a89;
  margin-top: 24px;
}

.icwtxt {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  gap: 10px;
  display: flex;
  align-items: center;
}

.icwtxt{
  margin-bottom: 16px;
}

.pd04_right_content{
  margin-bottom: 32px;
}

.pd04_right_content h5 {
  font-family: Proza Libre;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  color: #ADB4BD;
  margin-bottom: 8px;
}